const DEFAULT_API_STAGE = import.meta.env.VITE_API_STAGE || "staging";

export const STAGE_TO_API_URL: { [key: string]: string } = {
  development: "http://localhost:5000",
  staging: "https://dev-metadata-api.legitimate.tech",
  production: "https://metadata-api.legitimate.tech",
};

export const API_URL = STAGE_TO_API_URL[DEFAULT_API_STAGE] || STAGE_TO_API_URL.staging;

export const BUILDER_IO_PUBLIC_API_KEY = "a6378b4031b246ebad5efa7540a24082";

const MAGIC_AUTH_API_KEY_PRODUCTION = "pk_live_E661AD484C78066A";
const MAGIC_AUTH_API_KEY_STAGING = "pk_live_9DFA9BDAD587ACB5";

export const MAGIC_AUTH_API_KEY =
  DEFAULT_API_STAGE === "production"
    ? MAGIC_AUTH_API_KEY_PRODUCTION
    : MAGIC_AUTH_API_KEY_STAGING;
