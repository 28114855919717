import { post, get } from "utils/api";
import { getEndpointUrlByChainId } from "./mutations";
import { Address, createPublicClient, http } from "viem";
import { NETWORK, getChainById, getLgtWalletByChainId } from "utils/chains";
import LGTNFTLockedv3 from "abis/LGTNFTLockedv3";
import { TTag } from "types/api";

interface UnlockNFTArgs {
  chainId: NETWORK;
  cmac: string;
  ctr: string;
  uuid: string;
}

export const unlockNFT = ({ chainId, cmac, ctr, uuid }: UnlockNFTArgs) => {
  return post(getEndpointUrlByChainId(chainId, "nft/unlock"), {
    cmac,
    ctr,
    uuid,
  });
};

interface ClaimNFTArgs {
  chainId: NETWORK;
  ctr: string;
  cmac: string;
  uuid: string;
  account: string;
}

export const claimNFT = ({
  chainId,
  ctr,
  cmac,
  uuid,
  account,
}: ClaimNFTArgs) => {
  return post(getEndpointUrlByChainId(chainId, "nft/claim"), {
    walletAddress: account,
    ctr,
    cmac,
    uuid,
  });
};

interface ResetNFTOwnershipArgs {
  chainId: NETWORK;
  cmac: string;
  ctr: string;
  uuid: string;
}

export const resetNFTOwnership = ({
  chainId,
  cmac,
  ctr,
  uuid,
}: ResetNFTOwnershipArgs) => {
  return post(getEndpointUrlByChainId(chainId, "nft/recover"), {
    uuid,
    cmac,
    ctr,
  });
};

interface MintChildNFTArgs {
  standard: "erc721" | "erc1155";
  chainId: NETWORK;
  contractAddress: string;
  to: string;
  cmac: string;
  ctr: string;
  uuid: string;
  tokenId?: string;
}

export const mintChildNFT = ({ standard, ...args }: MintChildNFTArgs) => {
  return post(getEndpointUrlByChainId(args.chainId, `${standard}/mint`), {
    ...args,
    chainId: Number(args.chainId),
  });
};

export const getNFTMetadata = async (tag: TTag) => {
  try {
    const contract = {
      address: tag.nft_contract_address! as Address,
      abi: LGTNFTLockedv3,
    } as const;

    const client = createPublicClient({
      chain: getChainById(tag.nft_chain_id!),
      transport: http(),
    });

    const [owner, isLocked] = await Promise.all([
      client.readContract({
        ...contract,
        functionName: "ownerOf",
        args: [BigInt(tag.nft_token_id!)],
      }),
      client.readContract({
        ...contract,
        functionName: "getTokenLock",
        args: [BigInt(tag.nft_token_id!)],
      }),
    ]);

    if (!owner) {
      throw new Error("Error fetching NFT details");
    }

    const claimed =
      owner.toLowerCase() !==
      getLgtWalletByChainId(tag.nft_chain_id!)?.toLowerCase();

    let ownerName = undefined;

    if (claimed) {
      ownerName = await resolveOwnerName(owner);
    }

    return { owner, claimed, locked: !!isLocked, ownerName };
  } catch (error) {
    return { claimed: false };
  }
};

export const resolveOwnerName = async (ownerAddress: string) => {
  const response = await get(
    getEndpointUrlByChainId(1, `dynamic/user?address=${ownerAddress}`),
  );

  const { user } = await response.json();

  if (user?.firstName && user?.lastName) {
    return `${user.firstName} ${user.lastName}`;
  } else if (user?.username) {
    return user.username;
  } else if (user?.email) {
    return user.email;
  } else {
    return "Legitimate User";
  }
};
