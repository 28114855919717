import { useMutation } from "@tanstack/react-query";
import {
  claimNFT,
  mintChildNFT,
  resetNFTOwnership,
  resolveOwnerName,
  unlockNFT,
} from "./nft";
import useTagParams from "hooks/useTagParams";
import { useTagContext } from "hooks/useTagContext";
import { LGTContractVersion } from "types/api";
import { NETWORK, TESTNET_CHAIN_IDS, getChainById } from "utils/chains";
import { createPublicClient, http } from "viem";
import useDisplayContext from "hooks/useDisplayContext";
import { clearTagData } from "utils/cache";

const prodEndpointUrl = "https://api.legitimate.tech/";
const testEndpointUrl = "https://dev-api.legitimate.tech/";

export const getEndpointUrlByChainId = (chainId: NETWORK, route: string) => {
  if (TESTNET_CHAIN_IDS.has(chainId)) {
    return `${testEndpointUrl}${route}`;
  }
  return `${prodEndpointUrl}${route}`;
};

export const useUnlockNFTMutation = () => {
  const { tag } = useTagContext();
  const { uuid, ctr, cmac } = useTagParams();

  return useMutation({
    mutationKey: ["nft", "unlock", tag.nft_chain_id, cmac, ctr, uuid],
    mutationFn: async () => {
      const res = await unlockNFT({
        chainId: Number(tag.nft_chain_id),
        cmac: cmac!,
        ctr: ctr!,
        uuid: uuid!,
      });

      if (!res.ok) {
        throw new Error("Tag cannot be verified.");
      }

      const { hash } = await res.json();

      const client = createPublicClient({
        chain: getChainById(tag.nft_chain_id!),
        transport: http(),
      });

      const receipt = await client.waitForTransactionReceipt({ hash });

      return receipt.status === "success";
    },
  });
};

export const useClaimNFTMutation = () => {
  const { tag } = useTagContext();
  const { uuid, ctr, cmac } = useTagParams();
  const { state, dispatch } = useDisplayContext();

  return useMutation({
    mutationKey: [
      "nft",
      "claim",
      tag.nft_contract_address!,
      cmac,
      ctr,
      uuid,
      tag.nft_chain_id,
      tag.nft_token_id,
    ],
    mutationFn: async (account?: string) => {
      if (!account) {
        throw new Error("Wallet required to claim NFT");
      }

      if (state.claimed) {
        return;
      }

      const res = await claimNFT({
        chainId: Number(tag.nft_chain_id!),
        uuid: uuid!,
        ctr: ctr!,
        cmac: cmac!,
        account,
      });

      const { hash } = await res.json();

      const client = createPublicClient({
        chain: getChainById(tag.nft_chain_id!),
        transport: http(),
      });

      const [ownerName] = await Promise.all([
        resolveOwnerName(account),
        client.waitForTransactionReceipt({
          hash,
          confirmations: 3,
        }),
      ]);

      // Even if the transaction fails, we still follow the happy path.
      dispatch({
        claimed: true,
        owner: account,
        showClaimed: true,
        locked: false,
        ownerName,
      });

      return res;
    },
    gcTime: 1000 * 60, // 1 mins
  });
};

export const useResetNFTOwnershipMutation = () => {
  const { tag } = useTagContext();
  const { cmac, ctr, uuid } = useTagParams();
  const { dispatch } = useDisplayContext();

  return useMutation({
    mutationKey: ["nft", "recover"],
    mutationFn: async () => {
      if (!tag?.nft_chain_id || !cmac || !ctr || !uuid) {
        throw new Error("");
      }

      const response = await resetNFTOwnership({
        chainId: tag.nft_chain_id,
        cmac,
        ctr,
        uuid,
      });

      if (!response.ok) {
        throw new Error("Tag cannot be verified.");
      }

      const responseBody = await response.json();

      // TODO: This only handles the happy path. We should show a dialog/alert
      // if there are any issues with the claim transaction.

      // Return to the top of the screen
      window.screenTop && window.scrollTo(0, 0);
      clearTagData(tag.uuid);
      dispatch({ reset: true });

      return responseBody;
    },
  });
};

export const useMintChildNFTMutation = () => {
  const { tag } = useTagContext();
  const { cmac, ctr, uuid } = useTagParams();

  return useMutation({
    mutationKey: ["nft", "mint-child", tag.nft_contract_address!],
    mutationFn: async (account?: string) => {
      if (!account) {
        throw new Error("Wallet required to mint child NFT");
      }

      const standard =
        tag.secondary_contract_version ===
        LGTContractVersion.ERC1155_NFT_DISTRIBUTION
          ? "erc1155"
          : "erc721";

      const response = await mintChildNFT({
        standard,
        chainId: tag.nft_chain_id!,
        contractAddress: tag.nft_contract_address!,
        to: account,
        cmac: cmac!,
        ctr: ctr!,
        uuid: uuid!,
        ...(standard === "erc1155"
          ? { tokenId: String(tag.nft_token_id!) }
          : {}),
      });

      const { hash } = await response.json();

      const client = createPublicClient({
        chain: getChainById(tag.nft_chain_id!),
        transport: http(),
      });

      const receipt = await client.waitForTransactionReceipt({ hash });

      const parsedTokenId = receipt.logs[0].topics[3];

      if (
        receipt.status === "success" &&
        (standard === "erc1155" || !!parsedTokenId)
      ) {
        return {
          hash,
          tokenId:
            standard === "erc721" ? parsedTokenId! : String(tag.nft_token_id!),
        };
      } else {
        throw new Error("Transaction or parsing failed");
      }
    },
  });
};
