import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { DisplayProvider } from "context/DisplayContext";

import VerificationPage from "pages/verification";
import ErrorPage from "pages/error";
import SharePage from "pages/share";
import BuilderPage from "pages/b";

import { inputGlobalStyles } from "styles/global";
import { darkTheme } from "styles/theme";
import "./App.css";

import "config/builderIO";
import DesktopExperience from "components/DesktopExperience";
import useMobile from "hooks/useMobile";
import AppClipPage from "pages/appclip";

const App = () => {
  const isMobile = useMobile();

  if (!isMobile) {
    return <DesktopExperience />;
  }

  return (
    <React.Suspense fallback={<div />}>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <QueryClientProvider client={new QueryClient()}>
          <DisplayProvider>
            <BrowserRouter>
              <Routes>
                <Route index element={<VerificationPage />} />
                <Route path="/error" element={<ErrorPage />} />
                <Route path="/share" element={<SharePage />} />
                <Route path="/appclip" element={<AppClipPage />} />
                <Route
                  path="/b/:builderAPIKey/:builderPath"
                  element={<BuilderPage />}
                />
              </Routes>
            </BrowserRouter>
          </DisplayProvider>
          {inputGlobalStyles}
        </QueryClientProvider>
      </ThemeProvider>
    </React.Suspense>
  );
};

export default App;
