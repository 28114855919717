import { Box, Link, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import tagNotActivatedImage from "assets/images/error-states/tag-not-activated.webp";
import tapAgainVideo from "assets/images/error-states/tap-again.mp4";

import Header from "components/Header";
import { ERROR_TYPES } from "types/errors";
import Footer from "components/Footer";
import { errorTypeToMeaning } from "utils/error";

interface ErrorMessageProps {
  err: string;
  uuid: string;
  cmac?: string;
  ctr?: string;
}

const ErrorMessage = (props: ErrorMessageProps) => {
  const { t } = useTranslation();

  if (props.err === ERROR_TYPES.NOT_SHAREABLE) {
    return (
      <div>
        <Typography
          textTransform="uppercase"
          variant="h5"
          mt={1}
          mb={2}
          fontFamily="ABC Favorit"
        >
          {t(
            "error-page.not-shareable.title",
            "Oops, looks like you don't have access!",
          )}
        </Typography>
        <Typography variant="body2" mb={4}>
          {t(
            "error-page.not-shareable.subtitle",
            "This product is protected by Legitimate's anti-counterfeit solution.",
          )}
        </Typography>
        <Typography>
          <Trans t={t} i18nKey="error-page.not-shareable.description-tech">
            To safeguard product authenticity and the exclusive digital
            experience, refreshing and sharing functions are disabled. Learn
            more about our technology{" "}
            <a href="https://legitimate.tech/">here</a>.
          </Trans>
        </Typography>
        <Typography>
          <Trans
            t={t}
            i18nKey="error-page.not-shareable.description-customer-support"
          >
            If sharing is enabled for this link, and you are still see this
            screen, please <a href="https://legitimate.tech/contact">contact</a>{" "}
            Legitimate support with the following information:
          </Trans>
        </Typography>
        <ErrorMeta {...props} />
      </div>
    );
  } else if (props.err === ERROR_TYPES.INACTIVE_TAG) {
    return (
      <div>
        <Typography
          textTransform="uppercase"
          variant="h5"
          mt={1}
          mb={2}
          fontFamily="ABC Favorit"
        >
          {t("error-page.tag-not-activated.title", "Tag Not Activated")}
        </Typography>
        <Typography variant="body2" mb={2}>
          {t(
            "error-page.tag-not-activated.description",
            "We're sorry, but the Legitimate Tag attached to your item has not been activated, or its activation period has expired.",
          )}
        </Typography>
        <Box display="flex" justifyContent="center">
          <img
            style={{ width: "100%" }}
            alt="Hover again"
            src={tagNotActivatedImage}
          />
        </Box>
        <Typography textTransform="uppercase" fontWeight="bold" my={2}>
          {t("error-page.contact-creator.title", "Contact Creator")}
        </Typography>
        <Typography variant="body2" mb={2}>
          {t(
            "error-page.contact-creator.description",
            "Please contact the creator of your item or Legitimate with the following information.",
          )}
        </Typography>
        <ErrorMeta {...props} />
      </div>
    );
  }
  return (
    <div>
      <Typography
        textTransform="uppercase"
        variant="h5"
        mt={1}
        mb={2}
        fontFamily="ABC Favorit"
      >
        {t("error-page.tap-again.title", "Oops, Try Again!")}
      </Typography>
      <Typography variant="body2" mb={4}>
        {t(
          "error-page.tap-again.description",
          "This single use link generated by the Legitimate Tag on your item is invalid or has already been used. Try hovering your device over the tag on your product again.",
        )}
      </Typography>
      <Box display="flex" sx={{ width: "100%" }} justifyContent="center">
        <video
          loop
          autoPlay
          muted
          playsInline
          style={{ width: "100%", borderRadius: 5 }}
        >
          <source src={tapAgainVideo} type="video/mp4" />
        </video>
      </Box>
      <Typography textTransform="uppercase" fontWeight="bold" mb={2}>
        {t("error-page.anti-counterfeit.title", "Anti-Counterfeit")}
      </Typography>
      <Typography variant="body2" mb={4}>
        <Trans i18nKey="error.page.anti-counterfeit.description">
          This product is protected by Legitimate's anti-counterfeit solution.
          To safeguard product authenticity and the exclusive digital
          experience, refreshing and sharing functions are disabled. Learn more
          about our technology <Link href="https://legitimate.tech/">here</Link>
          .
        </Trans>
      </Typography>
      <Typography textTransform="uppercase" fontWeight="bold" mb={2}>
        {t("error-page.contact-us.title", "Contact Us")}
      </Typography>
      <Typography variant="body2" mb={2}>
        <Trans t={t} i18nKey="error-page.contact-us.description">
          If you still see this screen, please{" "}
          <Link href="https://legitimate.tech/">contact</Link> Legitimate
          through the website with the following information:
        </Trans>
      </Typography>
      <ErrorMeta {...props} />
    </div>
  );
};

const ErrorPage = () => {
  const [searchParams] = useSearchParams();

  return (
    <>
      <Box padding={2}>
        <header>
          <Header />
        </header>
        <div>
          <ErrorMessage
            err={searchParams.get("err") || ""}
            uuid={searchParams.get("uid") || ""}
            cmac={searchParams.get("cmac") || ""}
            ctr={searchParams.get("ctr") || ""}
          />
        </div>
      </Box>
      <Footer />
    </>
  );
};

interface ErrorMetaProps {
  uuid?: string;
  cmac?: string;
  ctr?: string;
  err?: string;
  tamper?: "CC" | "OO";
}

const ErrorMeta: React.FC<ErrorMetaProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Box
      padding={3}
      borderRadius={0.5}
      mb={2}
      sx={{ backgroundColor: "#374151", color: "#F9FAFB" }}
    >
      <Typography variant="body2">
        {t("error-page.meta.product-id", "Product ID")}:{" "}
        <code>{props.uuid || "N/A"}</code>
      </Typography>
      <Typography variant="body2">
        {t("error-page.meta.verification-code", "Verification Code")}:{" "}
        <code>{props.cmac || "N/A"}</code>
      </Typography>
      <Typography variant="body2">
        {t("error-page.meta.verification-attempt", "Verification Attempt")}:{" "}
        <code>{props.ctr || "N/A"}</code>
      </Typography>
      <Typography variant="body2">
        {t("error-page.meta.error-code", "Error Code")}:{" "}
        <code>{errorTypeToMeaning(props.err as ERROR_TYPES, t)}</code>
      </Typography>
      <Typography variant="body2">
        {t("error-page.meta.tag-tamper.title", "TagTamper")}:{" "}
        <code>
          {props.tamper
            ? t("error-page.meta.tag-tamper.enabled", "Enabled")
            : t("error-page.meta.tag-tamper.disabled", "Disabled")}
        </code>
      </Typography>
    </Box>
  );
};

export default ErrorPage;
